import { isFirebaseSupported } from 'helpers/auth'
import getFirebaseAuth from './index'

const signOut = async () => {
  if (isFirebaseSupported()) {
    const auth = await getFirebaseAuth()
    const { signOut } = await import('firebase/auth')
    const result = await signOut(auth)
    return result
  } else {
    throw new Error('Firebase signOut is not available in this environment')
  }
}

export { signOut }
