import { ErrorBoundary } from 'react-error-boundary'
import Button from 'components/Button'

type ErrorFallbackProps = {
  error: Error
  resetErrorBoundary: (...args: unknown[]) => void
}

export function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  return (
    <div className="bg-white px-4 py-12 sm:px-6 md:grid md:place-items-center lg:px-8 dark:bg-gray-900">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-brand-600 sm:text-5xl">Error</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6 dark:sm:border-gray-800">
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl dark:text-gray-100">
                Something went wrong.
              </h1>
              <p className="break-all mt-6 sm:mt-4 text-base text-gray-500 dark:text-gray-400">
                {error.message}
              </p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Button size="lg" variant="primary" onClick={resetErrorBoundary}>
                Go back
              </Button>
              <a href="mailto:feedback@txnlab.dev">
                <Button size="lg">Contact support</Button>
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

type ErrorProps = {
  children: React.ReactNode
}

export default function Error({ children }: ErrorProps) {
  return <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
}
