import dayjs from 'dayjs'
import { createContext, useContext, useEffect, useState } from 'react'
import { useAuthContext } from './AuthContext'
import { initializeFirebaseApp } from 'src/firebase/config'
import { isFirebaseSupported } from 'helpers/auth'

interface IContext {
  token: string
  notificationPermission: NotificationPermission
}

export const FcmContext = createContext<IContext>({} as IContext)

export const useFcmContext = () => useContext(FcmContext)

export const FcmContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [token, setToken] = useState<string>('')
  const [notificationPermission, setNotificationPermission] =
    useState<NotificationPermission>('default')

  const { user } = useAuthContext()

  useEffect(() => {
    const initializeMessaging = async () => {
      if (isFirebaseSupported()) {
        try {
          if (typeof window !== 'undefined' && 'serviceWorker' in navigator && user) {
            const app = await initializeFirebaseApp()
            const messagingModule = await import('firebase/messaging')
            const messaging = messagingModule.getMessaging(app)

            // Retrieve the notification permission status
            const permission = await Notification.requestPermission()
            setNotificationPermission(permission)

            // Check if permission is granted before retrieving the token
            if (permission === 'granted') {
              const currentToken = await messagingModule.getToken(messaging, {
                vapidKey: process.env.NEXT_PUBLIC_FIREBASE_FCM_VAPID_KEY
              })
              if (currentToken) {
                setToken(currentToken)

                // Set the token to Firestore w/ timestamp
                const db = await (await import('src/firebase/firestore')).default()
                const firestoreModule = await import('firebase/firestore')
                await firestoreModule.setDoc(
                  firestoreModule.doc(db, 'fcmTokens', user.uid),
                  {
                    token: currentToken,
                    timestamp: dayjs().toISOString()
                  },
                  { merge: true }
                )
              } else {
                console.warn('No registration token available. Request permission to generate one.')
              }
            }
          }
        } catch (error) {
          console.error('An error occurred while retrieving token:', error)
        }
      }
    }

    initializeMessaging()
  }, [user])

  useEffect(() => {
    let unsubscribe: (() => void) | null = null

    const subscribeToMessages = async () => {
      if (typeof window !== 'undefined' && 'serviceWorker' in navigator && user) {
        const app = await initializeFirebaseApp()
        const messagingModule = await import('firebase/messaging')
        const messaging = messagingModule.getMessaging(app)

        unsubscribe = messagingModule.onMessage(messaging, (payload) => {
          console.log('Foreground push notification received:', payload)
        })
      }
    }

    subscribeToMessages()

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [user])

  return (
    <FcmContext.Provider
      value={{
        token,
        notificationPermission
      }}
    >
      {children}
    </FcmContext.Provider>
  )
}
