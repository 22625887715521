import '../styles/globals.css'
import '../styles/keyframes.css'
import '../styles/auctions.css'
import '../styles/vault.css'
import '../styles/react-tooltip.css'
import '../styles/chat.css'
import { useState, useEffect, ReactElement, ReactNode } from 'react'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { RouterScrollProvider } from '@moxy/next-router-scroll'
import { createGlobalStyle, ThemeProvider as ScThemeProvider } from 'styled-components'
import { ThemeProvider } from 'next-themes'
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { HydrationProvider } from 'react-hydration-provider'
import * as ga from '../lib/ga'
import Toaster from '../components/Toaster'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Error from 'components/Error'
// import useLogging from 'hooks/useLogger'
import { shouldRetryQuery } from 'helpers/errors'
import { AuthAddressProvider } from 'context/AuthAddressContext'
import { AuthContextProvider } from 'context/AuthContext'
import { FcmContextProvider } from 'context/FcmContext'
import { UserAgentProvider } from 'context/UserAgentContext'
import { IsTouchSupportedProvider } from 'context/TouchSupportContext'
import { WalletManager, WalletProvider } from '@txnlab/use-wallet-react'
import { walletManagerConfig } from 'helpers/useWallet'

const walletManager = new WalletManager(walletManagerConfig)

const theme = {}

const GlobalStyle = createGlobalStyle``

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function App({ Component, pageProps }: AppPropsWithLayout) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: (failureCount, error) => {
              if (!shouldRetryQuery(error)) {
                return false
              }
              return failureCount < 3
            }
          }
        }
      })
  )

  const router = useRouter()

  useEffect(() => {
    async function loadPolyfill() {
      if (typeof window.IntersectionObserver === 'undefined') {
        console.log('Loading IntersectionObserver polyfill')
        await import('intersection-observer')
      }
    }
    loadPolyfill()
  }, [])

  // const { DebugDialog } = useLogging({ shouldPrompt: router.query.debug !== undefined })

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      ga.pageview(url)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  const renderLayout = () => {
    // Use page layout if available, or return the page
    const getLayout = Component.getLayout ?? ((page) => page)
    return getLayout(<Component {...pageProps} />)
  }

  return (
    <>
      <GlobalStyle />
      <Toaster />
      {/* <DebugDialog /> */}

      <UserAgentProvider>
        <IsTouchSupportedProvider>
          <HydrationProvider>
            <ScThemeProvider theme={theme}>
              <ThemeProvider attribute="class">
                <QueryClientProvider client={queryClient}>
                  <Hydrate state={pageProps.dehydratedState}>
                    <WalletProvider manager={walletManager}>
                      <AuthAddressProvider>
                        <AuthContextProvider>
                          <FcmContextProvider>
                            <Error>
                              <RouterScrollProvider>{renderLayout()}</RouterScrollProvider>
                            </Error>
                          </FcmContextProvider>
                        </AuthContextProvider>
                      </AuthAddressProvider>
                    </WalletProvider>
                  </Hydrate>
                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
              </ThemeProvider>
            </ScThemeProvider>
          </HydrationProvider>
        </IsTouchSupportedProvider>
      </UserAgentProvider>
    </>
  )
}

export default App
