// log page views
export const pageview = (url: string) => {
  window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
    page_path: url
  })
}

type GaEvent = {
  action: string
  params: {
    [key: string]: string
  }
}

// log specific events
export const event = ({ action, params }: GaEvent) => {
  window.gtag('event', action, params)
}
