import { getFirebaseAppId, getFirebaseMeasurementId } from 'helpers/auth'
import type { FirebaseApp } from 'firebase/app'

let firebaseAppPromise: Promise<FirebaseApp> | null = null

export const initializeFirebaseApp = () => {
  if (!firebaseAppPromise) {
    firebaseAppPromise = import('firebase/app').then((firebase) => {
      const firebaseConfig = {
        apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
        authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
        storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
        appId: getFirebaseAppId(),
        measurementId: getFirebaseMeasurementId()
      }
      return firebase.getApps().length === 0
        ? firebase.initializeApp(firebaseConfig)
        : firebase.getApps()[0]
    })
  }
  return firebaseAppPromise
}
