import { ALGORAND_NETWORK } from './constants'

export const WC_PROJECT_ID = 'db5553bc301f6543797d4f793d8c0431'

export const WC_NAME =
  ALGORAND_NETWORK === 'betanet'
    ? 'NFDomains Betanet'
    : ALGORAND_NETWORK === 'testnet'
    ? 'NFDomains Testnet'
    : 'NFDomains'

export const WC_DESCRIPTION =
  'Algorand name service and marketplace for Non-Fungible Domains — unique, readable identities for your wallet.'
