import { NfdUser } from './models/NfdUser'
import { initializeFirebaseApp } from '../config'
import { isFirebaseSupported } from 'helpers/auth'
import type {
  FirestoreDataConverter,
  PartialWithFieldValue,
  DocumentData,
  QueryDocumentSnapshot
} from 'firebase/firestore'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const converter = <T>(ModelClass: new (id: string, data: any) => T): FirestoreDataConverter<T> => ({
  toFirestore: (data: PartialWithFieldValue<T>): PartialWithFieldValue<DocumentData> =>
    data as PartialWithFieldValue<DocumentData>,
  fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>): T => {
    const id = snapshot.id
    const data = snapshot.data()
    return new ModelClass(id, data) as T
  }
})

// Wrap the typedRef and typedCollectionRef in a function that will dynamically load Firestore
export const getFirestoreRefs = async () => {
  if (isFirebaseSupported()) {
    const app = await initializeFirebaseApp()
    const firestoreModule = await import('firebase/firestore')
    const db = firestoreModule.getFirestore(app)

    const typedRef = <T>(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ModelClass: new (id: string, data: any) => T,
      path: string,
      ...pathSegments: string[]
    ) => {
      return firestoreModule.doc(db, path, ...pathSegments).withConverter(converter<T>(ModelClass))
    }

    const typedCollectionRef = <T>(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ModelClass: new (id: string, data: any) => T,
      path: string,
      ...pathSegments: string[]
    ) => {
      return firestoreModule
        .collection(db, path, ...pathSegments)
        .withConverter(converter<T>(ModelClass))
    }

    // Return the typedRef and typedCollectionRef functions
    return {
      docRef: {
        user: (uid: string) => typedRef<NfdUser>(NfdUser, 'users', uid)
      },
      collectionRef: {
        users: () => typedCollectionRef<NfdUser>(NfdUser, 'users')
      }
    }
  } else {
    throw new Error('Firestore utils are not available in this environment')
  }
}
