import { isFirebaseSupported } from 'helpers/auth'
import { initializeFirebaseApp } from '../config'
import type { Auth } from 'firebase/auth'

let authPromise: Promise<Auth> | null = null

const getFirebaseAuth = async () => {
  if (isFirebaseSupported()) {
    if (!authPromise) {
      authPromise = initializeFirebaseApp().then(async (app) => {
        const { getAuth } = await import('firebase/auth')
        return getAuth(app)
      })
    }
    return authPromise
  } else {
    throw new Error('Firebase Auth is not available in this environment')
  }
}

export default getFirebaseAuth

export * from './signIn'
export * from './signOut'
