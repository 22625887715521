import algodClient from 'lib/algodClient'
import { AccountInformation, Asset } from 'types/algosdk'
import type { AsaInfo } from 'types/node'

export const getAccountInfo = async (address: string, excludeAll = false) => {
  const accountInfo = excludeAll
    ? await algodClient.accountInformation(address).exclude('all').do()
    : await algodClient.accountInformation(address).do()

  if (!accountInfo) {
    throw new Error('Unable to get account info.')
  }

  return accountInfo as AccountInformation
}

export const getAssetByID = async (id: number | string) => {
  const assetId = typeof id === 'string' ? parseInt(id) : id

  const asset = await algodClient.getAssetByID(assetId).do()

  if (!asset) {
    throw new Error('Unable to get asset.')
  }

  return asset as Asset
}

export const isAssetOptedIn = async (address: string, assetId: number | string) => {
  const accountInfo = await getAccountInfo(address)
  const assets = accountInfo.assets || []

  return assets.some((asset) => asset['asset-id'] === parseInt(assetId as string))
}

// @todo: remove this when we remove support for legacy assets
export const isLegacyAsset = (object: unknown): object is AsaInfo => {
  return (
    typeof object === 'object' &&
    object !== null &&
    'asaID' in object &&
    typeof (object as AsaInfo).asaID === 'number'
  )
}
