export type Breakpoint =
  | 'default'
  | 'xs'
  | 'xs2'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'

export const breakpoints: { [key in Breakpoint]: number } = {
  default: 1,
  xs: 390,
  xs2: 412,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
  '3xl': 1600,
  '4xl': 1920,
  '5xl': 2560
}
