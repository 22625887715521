import { isFirebaseSupported } from 'helpers/auth'
import getFirebaseAuth from './index'

const signIn = async (token: string) => {
  if (isFirebaseSupported()) {
    const auth = await getFirebaseAuth()
    const { signInWithCustomToken } = await import('firebase/auth')
    const result = await signInWithCustomToken(auth, token)
    return result
  } else {
    throw new Error('Firebase signIn is not available in this environment')
  }
}

export { signIn }
