export type Caip10 = {
  account: string
  chain: string
  network: string
}

export type ActiveNfd = {
  accountsToWatch: string[]
  activatedAt: Date
  appID: number
  name: string
  owner: string
}

export type UserDocument = {
  algoAccount: string
  caip10: Caip10
  activeNFD?: ActiveNfd
  activeNFDAppID?: number
}

export class NfdUser {
  id: string
  algoAccount: string
  caip10: Caip10
  activeNFD?: ActiveNfd
  activeNFDAppID?: number

  constructor(id: string, doc: UserDocument) {
    this.id = id
    this.caip10 = doc.caip10
    this.activeNFD = doc.activeNFD
    this.algoAccount = doc.algoAccount
    this.activeNFDAppID = doc.activeNFDAppID
  }

  public get accountsToWatch(): string[] {
    return this.activeNFD?.accountsToWatch || []
  }

  public get hasActiveNfd(): boolean {
    return !!this.activeNFD
  }

  public get name(): string | null {
    return this.activeNFD?.name || null
  }

  public get nfdActivatedAt(): Date | null {
    return this.activeNFD?.activatedAt || null
  }

  public get account(): string {
    return this.caip10.account
  }
}
