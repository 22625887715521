import type { AxiosError } from 'axios'

/**
 * Type guard that checks an error of unknown type for existence of a
 * `statusCode` property.
 *
 * @param error the error to check
 * @returns true if the error contains a `statusCode` property
 */
export const hasStatusCode = (error: unknown): error is { statusCode: unknown } => {
  return typeof error === 'object' && error !== null && 'statusCode' in error
}

/**
 * Type guard that checks an error of unknown type for existence of a
 * `status` property.
 *
 * @param error the error to check
 * @returns true if the error contains a `status` property
 */
export const hasStatus = (error: unknown): error is { status: unknown } => {
  return typeof error === 'object' && error !== null && 'status' in error
}

/**
 * Checks if a failed query should be retried based on the status code
 * of the error that was thrown.
 *
 * @param error the error to check
 * @returns false if retries should be disabled, true otherwise
 */
export const shouldRetryQuery = (error: unknown): boolean => {
  const noRetryCodes = [403, 404]

  if (hasStatusCode(error)) {
    const statusCode = Number(error.statusCode)
    return !noRetryCodes.includes(statusCode)
  }

  if (hasStatus(error)) {
    const status = Number(error.status)
    return !noRetryCodes.includes(status)
  }

  return true
}

export function hasResponseStatus(error: unknown, status: number | number[]): boolean {
  const axiosError = error as AxiosError

  // Convert the input to an array if it's a single status code
  const statuses = Array.isArray(status) ? status : [status]

  return !!(axiosError.response && statuses.includes(axiosError.response.status))
}
