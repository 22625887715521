/* eslint-disable @typescript-eslint/no-empty-function */
import { useWallet } from '@txnlab/use-wallet-react'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { signOut } from 'src/firebase/auth'
import { initializeFirebaseApp } from 'src/firebase/config'
import { getFirestoreRefs } from 'src/firebase/firestore/utils'
import { isFirebaseSupported } from 'helpers/auth'
import type { FirebaseUser } from 'src/firebase/auth/types'
import type { NfdUser } from 'src/firebase/firestore/models/NfdUser'
import { useRouter } from 'next/router'
import usePush from 'hooks/usePush'

interface IContext {
  user: FirebaseUser | null
  nfdUser: NfdUser | null
}

export const AuthContext = createContext<IContext>({} as IContext)

export const useAuthContext = () => useContext(AuthContext)

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<FirebaseUser | null>(null)
  const [nfdUser, setNfdUser] = useState<NfdUser | null>(null)

  const isLoadedRef = useRef(false)

  const router = useRouter()
  const isRestrictedRoute =
    router.pathname.startsWith('/messages') || router.pathname.startsWith('/rooms')

  const push = usePush()

  useEffect(() => {
    const isLoaded = isLoadedRef.current
    if (isLoaded && !user && isRestrictedRoute) {
      push('/')
    }
  }, [isRestrictedRoute, push, user])

  useEffect(() => {
    let unsubscribeAuth = () => {}
    let unsubscribeUserDoc = () => {}

    const initializeAuthAndFirestore = async () => {
      if (isFirebaseSupported()) {
        try {
          const app = await initializeFirebaseApp()
          const authModule = await import('firebase/auth')
          const firestoreModule = await import('firebase/firestore')
          const { docRef } = await getFirestoreRefs()

          const auth = authModule.getAuth(app)

          unsubscribeAuth = authModule.onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
              setUser(firebaseUser)

              const ref = docRef.user(firebaseUser.uid)
              unsubscribeUserDoc = firestoreModule.onSnapshot(ref, (snapshot) => {
                console.log('Observed user document: ', snapshot.data())
                if (snapshot.exists()) {
                  setNfdUser(snapshot.data() as NfdUser)
                }
              })

              if (!isLoadedRef.current) {
                isLoadedRef.current = true
              }
            } else {
              setUser(null)
              setNfdUser(null)
            }
          })
        } catch (error) {
          console.error('Failed to initialize Firebase: ', error)
        }
      }
    }

    initializeAuthAndFirestore()

    return () => {
      unsubscribeAuth()
      unsubscribeUserDoc()
    }
  }, [])

  const { activeAddress } = useWallet()
  const isReady = true // @todo: remove if the effect below works in SSR

  const lastActiveAddressRef = useRef<string | null>(null)

  /**
   * Sign out user if wallet is disconnected or account is switched
   */
  useEffect(() => {
    if (isFirebaseSupported() && isReady) {
      const handleSignOut = () => {
        signOut().then(() => {
          setUser(null)
          setNfdUser(null)
        })
      }

      const lastActiveAddress = lastActiveAddressRef.current

      // Check for wallet disconnection
      if (!activeAddress) {
        handleSignOut()
      } else {
        // Check for wallet account switch
        if (lastActiveAddress && activeAddress !== lastActiveAddress) {
          handleSignOut()
        }
        // Update last active address
        lastActiveAddressRef.current = activeAddress
      }
    }
  }, [activeAddress, isReady])

  return (
    <AuthContext.Provider
      value={{
        user,
        nfdUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
