import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import toast, { Toaster, resolveValue } from 'react-hot-toast'
import { HiOutlineCheckCircle, HiOutlineXCircle, HiX } from 'react-icons/hi'
import Loading from './Loading'

export default function CustomToaster() {
  const renderIcon = (type: string) => {
    const getIcon = (type: string) => {
      switch (type) {
        case 'success':
          return <HiOutlineCheckCircle className="h-6 w-6 text-green-400 dark:text-green-500" />
        case 'error':
          return <HiOutlineXCircle className="h-6 w-6 text-red-400 dark:text-red-500" />
        case 'loading':
          return <Loading color="text-gray-900 dark:text-gray-300" size="h-6 w-6" />
        default:
          return null
      }
    }

    if (!['success', 'error', 'loading'].includes(type)) {
      return null
    }

    return <div className="flex-shrink-0">{getIcon(type)}</div>
  }

  return (
    <Toaster position="bottom-right">
      {(t) => (
        <Transition
          appear
          show={t.visible}
          as={Fragment}
          enter="transition transform ease-out duration-300"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="max-w-md w-full bg-white shadow-2xl rounded-lg pointer-events-auto flex ring-1 ring-gray-900 ring-opacity-5 sm:shadow-lg dark:bg-gray-800 dark:highlight">
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                {renderIcon(t.type)}

                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <div className="text-sm font-medium text-gray-900 dark:text-gray-400">
                    {resolveValue(t.message, t)}
                  </div>
                </div>

                {t.type !== 'loading' && (
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 dark:bg-transparent dark:text-gray-500 dark:hover:text-gray-400 dark:focus:ring-offset-gray-800 dark:focus:ring-gray-300"
                      onClick={() => toast.dismiss(t.id)}
                    >
                      <span className="sr-only">Close</span>
                      <HiX className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Transition>
      )}
    </Toaster>
  )
}
