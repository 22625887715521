export function getAlgorandCaip2(genesisHash: string): string {
  const urlSafe = genesisHash.replace(/\+/g, '-').replace(/\//g, '_')
  const caip2 = urlSafe.substring(0, 32)
  return caip2
}

export function getAlgorandCaip10(genesisHash: string, account: string): string {
  const prefix = getAlgorandCaip2(genesisHash)
  const identifier = `algorand:${prefix}`
  const caip10 = `${identifier}:${account}`
  return caip10
}

export function isFirebaseSupported(): boolean {
  return (
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'betanet' ||
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'testnet'
  )
}

export function getFirebaseAppId(): string {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'betanet') {
    return process.env.NEXT_PUBLIC_FIREBASE_BETANET_APP_ID || ''
  } else if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'testnet') {
    return process.env.NEXT_PUBLIC_FIREBASE_TESTNET_APP_ID || ''
  } else {
    return ''
  }
}

export function getFirebaseMeasurementId(): string {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'betanet') {
    return process.env.NEXT_PUBLIC_FIREBASE_BETANET_MEASUREMENT_ID || ''
  } else if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'testnet') {
    return process.env.NEXT_PUBLIC_TESTNET_FIREBASE_MEASUREMENT_ID || ''
  } else {
    return ''
  }
}
