export const algoExplorerUrl = process.env.NEXT_PUBLIC_EXPLORER_URL || 'https://algoexplorer.io/'

export const API_BASE_URL = process.env.NEXT_PUBLIC_API
export const WEBSOCKET_BASE_URL = API_BASE_URL?.replace('https://', 'wss://').replace(
  'http://',
  'ws://'
)

export const ALGORAND_NETWORK = process.env.NEXT_PUBLIC_VERCEL_ENV

export const isLocalnet = ALGORAND_NETWORK === 'localnet'
export const isBetanet = ALGORAND_NETWORK === 'betanet'
export const isTestnet = ALGORAND_NETWORK === 'testnet'
export const isMainnet = ALGORAND_NETWORK === 'mainnet'

export const CLIENT_BASE_URL = `https://app.${isMainnet ? '' : `${ALGORAND_NETWORK}.`}nf.domains`

export const TREASURY_ACCT = 'RSV2YCHXA7MWGFTX3WYI7TVGAS5W5XH5M7ZQVXPPRQ7DNTNW36OW2TRR6I'

export const ZERO_ADDRESS = 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY5HFKQ'

export const AUTH_RECEIVER = 'AUTHF4HTUYGZDEVDPL6XUTUFC7CRGJ4QGVR7WLVRBMX5G34IM3TPI77SJA'

export const DOCS_URL = process.env.NEXT_PUBLIC_DOCS_URL || 'https://docs.nf.domains'

export const NODE_ENV = process.env.NODE_ENV

export const NEXT_API_BASE_URL =
  process.env.NEXT_API_BASE_URL || `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`

export const LATEST_CONTRACT_VERSION_V2 = '2.12'

export const REGISTRY_APP_ID = (() => {
  switch (ALGORAND_NETWORK) {
    case 'mainnet':
      return 760937186
    case 'testnet':
      return 84366825
    case 'betanet':
      return 842656530
    default:
      return parseInt(process.env.NEXT_PUBLIC_REGISTRY_APP_ID || '0')
  }
})()

export const FEE_SINK = isMainnet
  ? 'Y76M3MSY6DKBRHBL7C3NNDXGS5IIMQVQVUAB6MP4XEMMGVF2QWNPL226CA'
  : 'A7NMWS3NT3IUDMLVO26ULGXGIIOUQ3ND2TXSER6EBGRZNOBOUIQXHIBGDE'
