import React from 'react'
import { clsxMerge } from 'helpers/utilities'

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset'
  className?: string
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  variant?: 'default' | 'primary' | 'gradient' | 'danger'
  disabled?: boolean
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  icon?: (props: any) => JSX.Element
  children: React.ReactNode
}

const BASE_CLASSES = 'inline-flex items-center justify-center shadow-sm font-medium group'

const VARIANTS = {
  default:
    'border border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:opacity-50 disabled:bg-white disabled:text-gray-700 dark:bg-gray-750 dark:border-transparent dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-offset-gray-900 dark:focus:ring-gray-100 dark:disabled:bg-gray-750',
  gradient:
    'border border-transparent text-white bg-gradient-to-r from-brand-400 to-brand-600 hover:from-brand-500 hover:to-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50 disabled:from-brand-400 disabled:to-brand-600 dark:bg-gradient-to-r dark:from-brand-500 dark:to-brand-600 dark:hover:from-brand-400 dark:hover:to-brand-600 dark:focus:ring-offset-gray-900 dark:ring-gray-100 dark:disabled:opacity-25 dark:disabled:from-brand-500 dark:disabled:to-brand-600',
  primary:
    'border border-transparent text-white bg-brand-500 hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50 disabled:bg-brand-500 dark:bg-brand-600 dark:hover:bg-brand-500 dark:focus:ring-offset-gray-900 dark:ring-gray-100 dark:disabled:opacity-25 dark:disabled:bg-brand-600',
  danger:
    'border border-transparent text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:bg-red-500 dark:bg-red-600 dark:hover:bg-red-500 dark:focus:ring-offset-gray-900 dark:ring-gray-100 dark:disabled:opacity-25 dark:disabled:bg-red-600'
}

const SIZES = {
  xs: 'px-2.5 py-1.5 text-xs rounded',
  sm: 'px-3.5 py-1.5 text-sm rounded-md',
  md: 'px-4 py-2 text-sm rounded-md',
  lg: 'px-4 py-2 text-base rounded-md',
  xl: 'px-6 py-3 text-base rounded-md'
}

const ICON_VARIANTS = {
  default:
    'text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-400',
  primary: 'text-white',
  gradient: 'text-white',
  danger: 'text-white'
}

const ICON_SIZES = {
  xs: '',
  sm: '-ml-0.5 mr-2 h-4 w-4',
  md: '-ml-1 mr-2 h-5 w-5',
  lg: '-ml-1 mr-3 h-5 w-5',
  xl: '-ml-1 mr-3 h-5 w-5'
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(props, ref) {
  const {
    type = 'button',
    className = '',
    size = 'md',
    variant = 'default',
    disabled = false,
    icon,
    children,
    ...rest
  } = props

  const Icon = ({ className }: { className: string }) => (icon ? icon({ className }) : null)

  const showIcon = icon && size !== 'xs'

  return (
    <button
      ref={ref}
      type={type}
      className={clsxMerge(BASE_CLASSES, SIZES[size], VARIANTS[variant], className)}
      disabled={disabled}
      {...rest}
    >
      {showIcon && (
        <Icon className={clsxMerge(ICON_SIZES[size], ICON_VARIANTS[variant])} aria-hidden="true" />
      )}
      {children}
    </button>
  )
})

export default Button
